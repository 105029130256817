<template>
  <div class="column no-padding is-full">
    <div class="card">
      <header class="card-header has-background-primary">
        <div @click.stop="$router.go(-1)" class="card-header-icon">
          <b-icon class="has-text-light" size="is-small" icon="arrow-left" />
        </div>
        <p class="card-header-title has-text-light">MATA KULIAH</p>
        <template v-if="isAdmin">
        <router-link
          v-if="isLoaded"
          :to="{ name: 'matakuliah-modul-add', params: {id: matakuliah.id} }"
          class="card-header-icon has-text-light"
        >
          <b-icon class="has-text-light" size="is-small" icon="plus" />
        </router-link>
        <template v-if="isLoaded && matakuliah.extra_data.can_change">
          <router-link
            :to="{ name: 'matakuliah-change', params: {id: matakuliah.id} }"
            class="card-header-icon has-text-light"
          >
            <b-icon class="has-text-light" size="is-small" icon="edit" />
          </router-link>
          <a
            class="card-header-icon"
            @click.stop.prevent="confirmDelete"
          >
            <b-icon icon="trash" size="is-small" class="has-text-light"></b-icon>
          </a>
        </template>
        </template>
      </header>
      <div class="card-content events-card">
        <div class="columns is-mobile">
          <div class="column detail has-text-weight-semibold is-4">Kode</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ matakuliah.kode }}</div>
        </div>
        <div class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold">Mata Kuliah</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ matakuliah.nama }}</div>
        </div>
        <b-table striped hoverable :data="matakuliah.kompetensi_set" :mobile-cards="false">
          <b-table-column v-slot="props" field="kuantitatif" label="Kuantitatif">{{ props.row.kuantitatif }}</b-table-column>
          <b-table-column v-slot="props" field="kualitatif" label="Kualitatif">{{ props.row.kualitatif }}</b-table-column>
          <b-table-column v-slot="props" v-if="canDeleteModul" field="delete" class="has-text-right">
            <a class="del-btn" @click="confirmDeleteModul(props.row)">
              <b-icon class="is-primary" size="is-small" icon="trash" />
            </a>
          </b-table-column>
        
          <template slot="empty">
            <div class="content has-text-grey has-text-centered">
              <p>Tidak ada data kompetensi.</p>
            </div>
          </template>
        </b-table>
        <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
      </div>
    </div>
  </div>
</template>


<script>
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import fetchCreatedMixin from "@/apps/core/mixins/fetchCreatedMixin";
import deleteMixin from "@/apps/core/mixins/deleteMixin";
import MataKuliah from "../models/matakuliah.js";
import { mapGetters } from "vuex";

export default {
  name: "MataKuliahDetail",
  props: ["title"],
  mixins: [onlineCheckMixin, fetchCreatedMixin, deleteMixin],
  data() {
    this.matkulMdl = new MataKuliah();
    this.objectMdl = this.matkulMdl;
    return this.matkulMdl.getObservables();
  },
  computed: {
    ...mapGetters("accounts", ["isAdmin"]),
    canDeleteModul() {
      return (
        this.isLoaded &&
        this.matakuliah.extra_data.can_change &&
        this.matakuliah.kompetensi_set.length > 1
      );
    }
  },
  methods: {
    fetchData() {
      this.matkulMdl.load(this.$route.params.id);
    },
    confirmDeleteModul(modul) {
      this.$buefy.dialog.confirm({
        title: "Menghapus Data",
        message: "Anda yakin akan <b>menghapus</b> materi ini?",
        confirmText: "Hapus Materi",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          let modulUrl = `kompetensi/${modul.id}`;
          this.matkulMdl.delete(modulUrl, () => {
            const idx = this.matakuliah.kompetensi_set.indexOf(modul);
            this.matakuliah.kompetensi_set.splice(idx, 1);
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/apps/core/assets/scss/detailview.scss";
.del-btn {
  color: #cd9777 !important;
}
</style>