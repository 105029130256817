import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "matakuliah";
const modelPath = `/${modelName}/`;

class MataKuliah extends ModelBase {
  constructor() {
    let model = {
        id: null,
        kode: "",
        nama: "",
        kompetensi_set: []
    };
    let requiredFields = ["nama", "kode"];
    super(modelName, model, requiredFields, [], modelPath);
  }
}

export default MataKuliah;